import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import useMediaQuery from 'hook/use-media-query';
import useWindowSize from 'hook/use-window-size';
import {
  CONTENT_WIDTH,
  CONTENT_PADDING,
  HEADER_NAV_ITEM_WIDTH,
  HEADER_LOGO_IMAGE_WIDTH,
  MIN_DESKTOP_SEARCH_BAR_WIDTH,
} from 'utils/themes/sizes';
import HeaderMobile from './mobile';
import HeaderDesktop from './desktop';

const Header = memo((props) => {
  const { tabs } = props;
  const { width } = useWindowSize();
  const isLaptop = useMediaQuery('laptop');
  const availableWidthForSearchBar = useMemo(
    () =>
      Math.min(width, CONTENT_WIDTH) -
      CONTENT_PADDING * 2 -
      HEADER_NAV_ITEM_WIDTH * (tabs.length + 1) -
      HEADER_LOGO_IMAGE_WIDTH -
      40,
    [width],
  );
  const isNotEnoughWidthForSearchBar = availableWidthForSearchBar < MIN_DESKTOP_SEARCH_BAR_WIDTH;

  return isLaptop || isNotEnoughWidthForSearchBar ? (
    <HeaderMobile />
  ) : (
    <HeaderDesktop availableSearchWidth={availableWidthForSearchBar} />
  );
});

Header.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      target: PropTypes.string,
      targetId: PropTypes.string,
      features: PropTypes.shape({
        history: PropTypes.bool,
      }),
      children: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          target: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
};

export default Header;
