import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'react-redux-firebase';
import ONBOARDING_FLOW_TYPES from 'constants/onboarding-flow-types';

const useContentPermission = () => {
  // TODO: this and all related functionality should be renamed to clearly reflect its relation to the paywall
  const isAuthenticated = useSelector(({ firebase }) => !isEmpty(firebase?.auth) ?? false);
  const hasSubscriptionFlow = useSelector(
    ({ app }) => app?.config?.onboarding?.some((item) => item?.module === ONBOARDING_FLOW_TYPES.paywall) ?? false,
  );
  const hasSubscriptionPermission = hasSubscriptionFlow
    ? useSelector(({ app }) => !!app.userSubscriptions && !!app.userSubscriptions?.length)
    : true;

  const hasEntitlementPermission = hasSubscriptionFlow
    ? useSelector(({ app }) => !!app.userEntitlements && !isEmpty(app.userEntitlements))
    : true;
  const hasContentPermissions = useMemo(
    () => (isAuthenticated && (hasSubscriptionPermission || hasEntitlementPermission)),
    [isAuthenticated, hasSubscriptionPermission, hasEntitlementPermission],
  );

  return { isAuthenticated, hasSubscriptionFlow, hasSubscriptionPermission, hasEntitlementPermission, hasContentPermissions };
};

export default useContentPermission;
