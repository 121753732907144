import axios from 'axios';
import firebase from 'utils/auth/firebase';

const service = axios.create();

service.interceptors.request.use(async (config) => {
  const token = await firebase?.auth()?.currentUser?.getIdToken?.();

  return token
    ? {
        ...config,
        headers: {
          ...config.headers,
          'X-API-Version': 4,
          Authorization: `Firebase ${token}`,
        },
      }
    : config;
});

export default service;
