import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles((theme) => ({
  navLink: {
    textAlign: 'center',
    fontSize: 14,
    lineHeight: '18px',
    display: 'inline-block',
    width: 106,
    textDecoration: 'none',
    fontFamily: 'ARSMaquetteProMedium',
    color: theme.palette.colors.surfaceOnColor,
    '@media (hover: hover)': {
      '&:hover': {
        color: theme.palette.colors.primaryColor,
      },
    },
  },
  activeNavLink: {
    color: theme.palette.colors.primaryColor,
  },
  lastNavLink: {
    marginRight: 10,
  },
}));

export default useStyles;
