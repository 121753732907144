import firebase from 'utils/auth/firebase';

export const signUp = async ({ email, password }) => firebase.auth().createUserWithEmailAndPassword(email, password);

export const login = async ({ email, password }) => firebase.auth().signInWithEmailAndPassword(email, password);

export const resetPassword = async ({ email }) => firebase.auth().sendPasswordResetEmail(email);

export const loginWithCustomToken = async (token) => firebase.auth().signInWithCustomToken(token);

export const logout = async () => firebase.auth().signOut();
