import React, { memo, useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import useWindowSize from 'hook/use-window-size';
import useOauthPopup from 'hook/use-oauth-popup';
import useContentPermission from 'hook/use-content-permission';
import {
  CONTENT_PADDING,
  CONTENT_WIDTH,
  HEADER_LOGO_IMAGE_WIDTH,
  MIN_DESKTOP_SEARCH_BAR_WIDTH,
} from 'utils/themes/sizes';
import ROUTES, { getTabPageRoute } from 'utils/routes';
import { generateUserSubscriptionPortalLink } from 'utils/firestore';
import AUTH_TYPE from 'constants/auth-type';
import Loader from 'components/loader';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import Slide from '@material-ui/core/Slide';
import ListItem from '@material-ui/core/ListItem';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import { Paper } from '@material-ui/core';
import NavLink from '../components/nav-link';
import SearchInput from '../components/search-input';
import useStyles from './header.mobile.styles';
import CollapseNavItem from './collapse-nav-item';

const HeaderMobile = memo((props) => {
  const classes = useStyles();
  const { width } = useWindowSize();
  const { t } = useTranslation();
  const { isAuthenticated, hasContentPermissions, hasSubscriptionFlow, hasSubscriptionPermission } =
    useContentPermission();
  const { tabs, appName, authType, brandImage, displayName, logoutAction, firebaseFunctionLocation } = props;

  const [isPortalLinkLoading, setIsPortalLinkLoading] = useState(false);

  const availableWidthForSearchBar = useMemo(
    () => Math.min(width, CONTENT_WIDTH) - CONTENT_PADDING * 2 - HEADER_LOGO_IMAGE_WIDTH - 70,
    [width],
  );
  const isNotEnoughWidthForSearchBar = availableWidthForSearchBar < MIN_DESKTOP_SEARCH_BAR_WIDTH;

  const [isOpen, setIsOpen] = useState(false);

  const openMenu = useCallback(() => setIsOpen(true), []);
  const closeMenu = useCallback(() => setIsOpen(false), []);

  const renderBurgerButton = useCallback(
    () => (
      <IconButton onClick={isOpen ? closeMenu : openMenu} className={classes.burgerIconBtn}>
        {isOpen ? <CloseIcon /> : <MenuIcon />}
      </IconButton>
    ),
    [isOpen],
  );

  const handleWebAuth = useOauthPopup({ onClose: closeMenu });

  const handleLogoutClick = useCallback(() => {
    closeMenu();
    logoutAction();
  }, [closeMenu, logoutAction]);

  const handleSubscriptionAccount = useCallback(() => {
    setIsPortalLinkLoading(true);
    generateUserSubscriptionPortalLink(firebaseFunctionLocation)
      .then((userPortalLink) => {
        window.location.href = userPortalLink;
      })
      .catch(() => setIsPortalLinkLoading(false));
  }, [firebaseFunctionLocation]);

  return (
    <AppBar position="absolute" className={classes.header}>
      <Toolbar variant="dense" className={classes.toolBar}>
        <Link to={ROUTES.root}>
          <img src={brandImage} alt={appName} className={classes.logoImage} />
        </Link>
        <nav className={classes.navBar}>
          {isAuthenticated ? (
            <>
              {hasContentPermissions && !isNotEnoughWidthForSearchBar && (
                <SearchInput availableSearchWidth={availableWidthForSearchBar} />
              )}
              {renderBurgerButton()}
            </>
          ) : (
            renderBurgerButton()
          )}
        </nav>
      </Toolbar>
      {isAuthenticated && hasContentPermissions && isNotEnoughWidthForSearchBar && (
        <Box className={classes.searchInputContainer}>
          <SearchInput collapsed={false} />
        </Box>
      )}
      <Slide in={isOpen} mountOnEnter unmountOnExit className={classes.burgerMenuContainer}>
        <Box>
          <ClickAwayListener onClickAway={closeMenu}>
            <Paper className={classes.paper}>
              <List role="presentation" className={classes.burgerMenuList}>
                {isAuthenticated ? (
                  <>
                    {hasContentPermissions &&
                      tabs.map((tab) => {
                        switch (tab.target) {
                          case 'browse_multi':
                            return (
                              <CollapseNavItem
                                key={tab.label}
                                label={tab.label}
                                items={tab.children}
                                onClick={closeMenu}
                              />
                            );
                          case 'feed':
                          case 'library':
                          default:
                            return (
                              <>
                                <ListItem key={tab.label} className={classes.burgerMenuItem}>
                                  <NavLink href={getTabPageRoute(tab.target, tab.targetId)} onClick={closeMenu}>
                                    {t(tab.label)}
                                  </NavLink>
                                </ListItem>
                              </>
                            );
                        }
                      })}
                    {hasSubscriptionFlow && hasSubscriptionPermission && (
                      <ListItem
                        disabled={isPortalLinkLoading}
                        onClick={handleSubscriptionAccount}
                        className={classes.burgerMenuItem}
                      >
                        <Typography noWrap component="span" className={classes.burgerItemText}>
                          {t`settings_label_manage`}
                        </Typography>
                        {isPortalLinkLoading && (
                          <Box className={classes.itemLoader}>
                            <Loader />
                          </Box>
                        )}
                      </ListItem>
                    )}
                    <ListItem noWrap className={classes.burgerMenuItem}>
                      <Typography noWrap component="span" className={classes.burgerItemText}>
                        {displayName}
                      </Typography>
                    </ListItem>
                    <ListItem onClick={handleLogoutClick} className={classes.burgerMenuItem}>
                      <Typography noWrap component="span" className={classes.burgerItemText}>
                        {t`settings_label_logout`}
                      </Typography>
                    </ListItem>
                  </>
                ) : (
                  <>
                    {authType === AUTH_TYPE.firebase && (
                      <>
                        <ListItem className={classes.burgerMenuItem}>
                          <NavLink href={ROUTES.signup} onClick={closeMenu}>
                            {t`auth_firebase_button_signup`}
                          </NavLink>
                        </ListItem>
                        <ListItem className={classes.burgerMenuItem}>
                          <NavLink href={ROUTES.login} onClick={closeMenu}>
                            {t`auth_firebase_button_login`}
                          </NavLink>
                        </ListItem>
                      </>
                    )}
                    {authType === AUTH_TYPE.web && (
                      <>
                        <ListItem className={classes.burgerMenuItem}>
                          <Typography
                            noWrap
                            component="span"
                            onClick={handleWebAuth}
                            className={classes.burgerItemText}
                          >
                            {t`auth_firebase_button_login`}
                          </Typography>
                        </ListItem>
                      </>
                    )}
                  </>
                )}
              </List>
            </Paper>
          </ClickAwayListener>
        </Box>
      </Slide>
    </AppBar>
  );
});

HeaderMobile.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      target: PropTypes.string,
      target_id: PropTypes.string,
      features: PropTypes.shape({
        history: PropTypes.bool,
      }),
      children: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          target: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  appName: PropTypes.string.isRequired,
  authType: PropTypes.string.isRequired,
  brandImage: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired,
  logoutAction: PropTypes.func.isRequired,
  firebaseFunctionLocation: PropTypes.string,
};

export default HeaderMobile;
