/* eslint-disable import/no-cycle */
import { createRoutine } from 'redux-saga-routines';
import { getTracks } from 'api/tracks';
import logger from 'utils/logger';
import { READER_THEMES } from 'utils/themes';
import { isVideoContentType } from 'utils/assets';
import { PLAYER_PERSIST_KEY, setPersistentData } from 'utils/localstorage';
import ASSET from 'constants/asset-types';
import { select, put, takeLatest, takeEvery, call } from 'redux-saga/effects';
import { callPingSaga, getConfig } from '../app';
import { addToHistorySaga } from '../history';

export const playTrackRoutine = createRoutine('PLAY_TRACK');
export const pauseTrackRoutine = createRoutine('PAUSE_TRACK');
export const changeIsPIPModeRoutine = createRoutine('CHANGE_IS_PIP_MODE');
export const changeFullAudioPlayerViewRoutine = createRoutine('CHANGE_FULL_AUDIO_PLAYER_VIEW');
export const changeFullVideoPlayerViewRoutine = createRoutine('CHANGE_FULL_VIDEO_PLAYER_VIEW');
export const changeEbookReaderOpenRoutine = createRoutine('CHANGE_EBOOK_READER_OPEN');
export const playPrevTrackInQueueRoutine = createRoutine('PLAY_PREV_TRACK_IN_QUEUE');
export const playNextTrackInQueueRoutine = createRoutine('PLAY_NEXT_TRACK_IN_QUEUE');
export const updateQueueRoutine = createRoutine('UPDATE_QUEUE');
export const preloadQueueTracksRoutine = createRoutine('PRELOAD_QUEUE_TRACKS');
export const replaceQueueRoutine = createRoutine('REPLACE_QUEUE');
export const changePlaybackRateRoutine = createRoutine('CHANGE_PLAYBACK_RATE');
export const changeVolumeRoutine = createRoutine('CHANGE_VOLUME_LEVEL');
export const changeActiveTrackIndexRoutine = createRoutine('CHANGE_ACTIVE_TRACK_INDEX');
export const updateEBookDataRoutine = createRoutine('UPDATE_EBOOK_DATA');
export const resetPlayerRoutine = createRoutine('RESET_PLAYER');

const NUMBER_OF_TRACKS_TO_PRELOAD = 3;

export const PLAYBACK_RATES = Object.freeze({
  1.25: 1.25,
  normal: 1,
  0.75: 0.75,
});

export const initialState = Object.freeze({
  volume: 1,
  queue: [],
  position: 0,
  ebookData: {
    activeTheme: READER_THEMES.light,
    totalPages: 0,
    currentPage: 1,
  },
  isPIPMode: false,
  isPlaying: false,
  activeIndex: null,
  activePlaylistId: null,
  isEbookReaderOpen: false,
  isFullAudioPlayerOpened: false,
  isFullVideoPlayerOpened: false,
  playbackRate: PLAYBACK_RATES.normal,
});

export const playerReducer = (state = initialState, action) => {
  switch (action.type) {
    case playTrackRoutine.REQUEST:
      return {
        ...state,
        isPlaying: true,
        isFullVideoPlayerOpened:
          isVideoContentType(state.queue?.[state.activeIndex]) && !state.isPIPMode
            ? true
            : state.isFullVideoPlayerOpened,
      };
    case pauseTrackRoutine.REQUEST:
      return {
        ...state,
        isPlaying: false,
      };
    case changeIsPIPModeRoutine.REQUEST:
      return {
        ...state,
        isPIPMode: action.payload,
      };
    case changeFullAudioPlayerViewRoutine.REQUEST:
      return {
        ...state,
        isPIPMode: action.payload ? false : state.isPIPMode,
        isFullAudioPlayerOpened: action.payload,
        isFullVideoPlayerOpened: action.payload ? false : state.isFullVideoPlayerOpened,
        isEbookReaderOpen: action.payload ? false : state.isEbookReaderOpen,
      };
    case changeFullVideoPlayerViewRoutine.REQUEST:
      return {
        ...state,
        isPIPMode: action.payload ? false : state.isPIPMode,
        isFullVideoPlayerOpened: action.payload,
        isFullAudioPlayerOpened: action.payload ? false : state.isFullAudioPlayerOpened,
        isEbookReaderOpen: action.payload ? false : state.isEbookReaderOpen,
      };
    case changeEbookReaderOpenRoutine.REQUEST:
      return {
        ...state,
        isPIPMode: action.payload ? false : state.isPIPMode,
        isPlaying: action.payload ? false : state.isPlaying,
        isFullAudioPlayerOpened: action.payload ? false : state.isFullAudioPlayerOpened,
        isFullVideoPlayerOpened: action.payload ? false : state.isFullVideoPlayerOpened,
        isEbookReaderOpen: action.payload,
      };
    case updateEBookDataRoutine.SUCCESS:
      return {
        ...state,
        ebookData: {
          ...state.ebookData,
          ...action.payload,
        },
      };
    case changeActiveTrackIndexRoutine.REQUEST:
      return {
        ...state,
        isPlaying: state.activeIndex !== action.payload ? true : state.isPlaying,
        activeIndex: action.payload,
      };
    case updateQueueRoutine.REQUEST:
    case preloadQueueTracksRoutine.SUCCESS:
      return {
        ...state,
        queue: action.payload,
      };
    case replaceQueueRoutine.SUCCESS:
      return {
        ...state,
        position: 0,
        isPlaying: action.payload.isPlaying ?? true,
        activeIndex: action.payload.activeIndex,
        activePlaylistId: action.payload.activePlaylistId ?? null,
        queue: action.payload.queue,
      };
    case changeVolumeRoutine.REQUEST:
      return {
        ...state,
        volume: action.payload,
      };
    case changePlaybackRateRoutine.REQUEST:
      return {
        ...state,
        playbackRate: action.payload,
      };
    case playPrevTrackInQueueRoutine.REQUEST: {
      const prevTrackIndex = state.activeIndex > 0 ? state.activeIndex - 1 : state.activeIndex;

      return {
        ...state,
        isPlaying: prevTrackIndex === state.activeIndex ? state.isPlaying : true,
        activeIndex: prevTrackIndex,
      };
    }
    case playNextTrackInQueueRoutine.REQUEST: {
      const nextTrackIndex = state.activeIndex < state.queue.length - 1 ? state.activeIndex + 1 : state.activeIndex;

      return {
        ...state,
        isPlaying: nextTrackIndex === state.activeIndex ? state.isPlaying : true,
        activeIndex: nextTrackIndex,
      };
    }
    case resetPlayerRoutine.REQUEST:
      return {
        ...initialState,
        volume: state.volume,
        playbackRate: state.playbackRate,
      };
    default:
      return { ...state };
  }
};

export const getQueue = ({ player: { queue } }) => queue;
export const getVolume = ({ player: { volume } }) => volume;
export const getEbookData = ({ player: { ebookData } }) => ebookData;
export const getPlaybackRate = ({ player: { playbackRate } }) => playbackRate;
export const getActiveTrackIndex = ({ player: { activeIndex } }) => activeIndex;

export function* replaceQueueSaga({ payload }) {
  const { queue: outerQueue, activeIndex: outerActiveIndex } = payload;

  // Hack added by Joe to unwrap Assets (see `mapTrackToAsset`)
  let activeIndex = 0;
  const queue = outerQueue.reduce((acc, outerAsset, index) => {
    if (index < outerActiveIndex) {
      activeIndex += outerAsset.allAssets.length || 1;
    }
    if (outerAsset.allAssets.length) {
      return [
        ...acc,
        ...outerAsset.allAssets.map((innerAsset) => ({
          ...outerAsset,
          ...innerAsset,
        })),
      ];
    }
    return [...acc, ...[outerAsset]];
  }, []);
  // End hack

  const currentQueueItem = queue[activeIndex];
  const contentType = currentQueueItem?.contentType;

  switch (contentType) {
    case ASSET.contentType.audio: {
      const filterFunction = (item) => item?.contentType === contentType;
      const filteredLeftPart = queue.slice(0, activeIndex).filter(filterFunction);
      const filteredRightPart = queue.slice(activeIndex + 1, queue.length).filter(filterFunction);
      const { isAutoOpenPlayerFullScreen } = yield select(getConfig);

      yield put(
        replaceQueueRoutine.success({
          ...payload,
          activeIndex: filteredLeftPart.length,
          queue: [...filteredLeftPart, currentQueueItem, ...filteredRightPart],
        }),
      );
      yield put(changeEbookReaderOpenRoutine.request(false));
      yield put(changeFullVideoPlayerViewRoutine.request(false));

      if (isAutoOpenPlayerFullScreen) {
        yield put(changeFullAudioPlayerViewRoutine.request(true));
      }
      break;
    }
    case ASSET.contentType.video: {
      const filterFunction = (item) => item?.contentType === contentType;
      const filteredLeftPart = queue.slice(0, activeIndex).filter(filterFunction);
      const filteredRightPart = queue.slice(activeIndex + 1, queue.length).filter(filterFunction);

      yield put(
        replaceQueueRoutine.success({
          ...payload,
          activeIndex: filteredLeftPart.length,
          queue: [...filteredLeftPart, currentQueueItem, ...filteredRightPart],
        }),
      );
      yield put(changeEbookReaderOpenRoutine.request(false));
      yield put(changeFullVideoPlayerViewRoutine.request(true));
      yield put(changeFullAudioPlayerViewRoutine.request(false));

      break;
    }
    case ASSET.contentType.ebook: {
      yield put(
        replaceQueueRoutine.success({
          activeIndex: 0,
          isPlaying: false,
          queue: [currentQueueItem],
          activePlaylistId: payload.activePlaylistId,
        }),
      );
      yield put(changeEbookReaderOpenRoutine.request(true));
      yield put(changeFullAudioPlayerViewRoutine.request(false));
      yield put(changeFullVideoPlayerViewRoutine.request(false));
      break;
    }
    default:
      break;
  }
}

export function* preloadQueueTracksSaga() {
  try {
    const queue = yield select(getQueue);
    const activeIndex = yield select(getActiveTrackIndex);

    const itemsToPreload = queue
      .slice(activeIndex - NUMBER_OF_TRACKS_TO_PRELOAD, activeIndex + NUMBER_OF_TRACKS_TO_PRELOAD)
      .filter((item) => !!item);
    const filteredLoadedItemsIds = itemsToPreload
      .filter((item) => !item?.id && item.trackId && item.assetId)
      .reduce((acc, item) => ({ ...acc, [`${item.trackId}_${item.assetId}`]: item.trackId }), {});

    if (Object.keys(filteredLoadedItemsIds).length) {
      const loadedItems = yield call(getTracks, filteredLoadedItemsIds);
      const mappedLoadedItems = loadedItems.reduce((acc, item) => ({ ...acc, [item.trackId]: item }), {});
      const mappedLoadedItemsWithAssets = loadedItems.reduce(
        (acc, item) => ({ ...acc, [`${item.trackId}_${item.assetId}`]: item }),
        {},
      );
      const newQueue = queue.map((currentItem) => {
        const mappedItem = currentItem.assetId
          ? mappedLoadedItemsWithAssets[`${currentItem.trackId}_${currentItem.assetId}`]
          : mappedLoadedItems[currentItem.trackId];

        return mappedItem ? { ...currentItem, ...mappedItem } : currentItem;
      });

      yield put(preloadQueueTracksRoutine.success(newQueue));
    }
  } catch (error) {
    logger.log('preloadQueueTracksSaga', 'Error while preloading queue items', error);
  }
}

export function* updateEBookDataSaga({ payload }) {
  const ebookData = yield select(getEbookData);
  const { skipHistoryUpdate = false, ...newEbookData } = payload;
  const { currentPage } = newEbookData;

  try {
    if (currentPage && !skipHistoryUpdate && ebookData.totalPages) {
      yield call(callPingSaga, { payload: { position: currentPage, length: ebookData.totalPages } });
      yield call(addToHistorySaga, { payload: { progress: currentPage, duration: ebookData.totalPages } });
    }

    yield put(updateEBookDataRoutine.success(newEbookData));
  } catch (error) {
    logger.log('updateEBookDataSaga', 'Error while updating ebook data', error);
  }
}

export function* resetPlayerSaga() {
  try {
    const queue = yield select(getQueue);
    const volume = yield select(getVolume);
    const ebookData = yield select(getEbookData);
    const playbackRate = yield select(getPlaybackRate);
    const activeIndex = yield select(getActiveTrackIndex);

    setPersistentData(PLAYER_PERSIST_KEY, {
      queue,
      volume,
      playbackRate,
      activeIndex,
      ebookData,
      position: 0,
    });
  } catch (error) {
    logger.log('resetPlayerSaga', 'Error while resetting player.', error);
  }
}

export function* watchPlayerSagas() {
  yield takeLatest(resetPlayerRoutine.REQUEST, resetPlayerSaga);
  yield takeLatest(replaceQueueRoutine.REQUEST, replaceQueueSaga);
  yield takeEvery(updateEBookDataRoutine.REQUEST, updateEBookDataSaga);
  yield takeEvery(preloadQueueTracksRoutine.REQUEST, preloadQueueTracksSaga);
}
