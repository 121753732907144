import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { ebookSettingsSharedProps } from 'utils/prop-types';
import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import Icons from 'components/icons';
import { FONT_SCALE_STEPS, FONT_SCALE_CHANGE_TYPE } from '../settings-menu.constants';
import useStyles from './lineheight-panel.styles';

const LineHeightPanel = memo(({ readerRef, lineHeight, updateOptions, commonClasses }) => {
  const classes = useStyles();

  const changeFontScale = useCallback(
    (scaleValue) => () => {
      const nextValue = FONT_SCALE_STEPS[FONT_SCALE_STEPS.indexOf(lineHeight ?? 1) + Number(scaleValue)];

      if (nextValue) {
        readerRef.current.applyUserSettings({ lineHeight: nextValue })
        updateOptions({ lineHeight: nextValue });
      }
    },
    [lineHeight],
  );

  return (
    <Box className={classes.lineHeightContainer}>
      <IconButton onClick={changeFontScale(FONT_SCALE_CHANGE_TYPE.decrease)} className={commonClasses.iconBtn}>
        <Icons.ReaderLineHeightSm className={commonClasses.iconSm} />
      </IconButton>
      <IconButton onClick={changeFontScale(FONT_SCALE_CHANGE_TYPE.increase)} className={commonClasses.iconBtn}>
        <Icons.ReaderLineHeightLg className={commonClasses.iconSm} />
      </IconButton>
    </Box>
  );
});

LineHeightPanel.propTypes = {
  lineHeight: PropTypes.number.isRequired,
  ...ebookSettingsSharedProps,
};

export default LineHeightPanel;
