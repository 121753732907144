/* eslint-disable no-console */

import i18n from 'i18next';
import detectorLanguage from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

import { defaultNS, fallbackLng, interpolation } from './config.json';

const parseMissingKeyHandler = (key) => {
  console.warn(`Missing value for key ${key}`);

  return '';
};
// eslint-disable-next-line global-require, import/no-dynamic-require
const getTranslates = (language = fallbackLng) => require(`./languages/${language}.properties.json`);

const initI18n = (options = {}) => {
  const { locales = [], overwriteTranslations = {} } = options;
  const fallbackLanguage = locales.includes(fallbackLng) ? fallbackLng : locales[0];

  const resources = locales.reduce(
    (acc, locale) => ({
      ...acc,
      [locale]: {
        [defaultNS]: { ...getTranslates(locale), ...overwriteTranslations[locale] },
      },
    }),
    {},
  );

  i18n
    .use(detectorLanguage)
    .use(initReactI18next)
    .init({
      load: 'currentOnly',  // Example: en-US, 'all' -> ['en-US', 'en', 'dev'], 'currentOnly' -> 'en-US', 'languageOnly' -> 'en'
      whitelist: locales,
      defaultNS,
      interpolation,
      fallbackLng: fallbackLanguage,
      parseMissingKeyHandler,
      useSuspense: true,
      resources,
      ...options,
    });

  return i18n;
};

export default initI18n;
