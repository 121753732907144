import { createTheme, darken } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import fronts from './fonts';
import { HEADER_HEIGHT } from './sizes';

export const READER_THEMES = Object.freeze({
  light: 'light',
  sepia: 'sepia',
  dark: 'dark',
});

const READER_HIGHLIGHT_COLORS = Object.freeze({
  blue: '#86b5e1',
  yellow: '#fceb23',
  green: '#b7d543',
  red: '#d982b7',
  purple: '#a26ab1',
});

const createMuiTheme = ({ colors }) =>
  createTheme({
    palette: {
      primary: {
        main: colors.primaryColor,
      },
      background: {
        default: colors.backdropColor,
      },
      colors,
    },
    reader: {
      [READER_THEMES.light]: {
        mainColors: {
          accent: '#393798',
          backgroundLight: '#ffffff',
          backgroundDark: '#f9f9f9',
          foregroundLight: '#171717',
          foregroundDark: '#171717',
        },
        highlightColors: READER_HIGHLIGHT_COLORS,
      },
      [READER_THEMES.sepia]: {
        mainColors: {
          accent: '#7a5b48',
          backgroundLight: '#f9e6d9',
          backgroundDark: '#e4d1c4',
          foregroundLight: '#776965',
          foregroundDark: '#4c413e',
        },
        highlightColors: READER_HIGHLIGHT_COLORS,
      },
      [READER_THEMES.dark]: {
        mainColors: {
          accent: '#d48872',
          backgroundLight: '#303030',
          backgroundDark: '#202020',
          foregroundLight: '#ffffffb3',
          foregroundDark: '#ffffff',
        },
        highlightColors: READER_HIGHLIGHT_COLORS,
      },
    },
    videoPlayer: {
      colors: {
        videoBackdropColor: '#000',
        videoBackdropOnColor: '#FFF',
        videoSurfaceColor: '#FFF',
        videoSurfaceOnColor: '#000',
        videoSurfaceMutedColor: '#eee',
      },
    },
    typography: {
      fontFamily: 'ARSMaquetteProMedium',
    },
    overrides: {
      MuiCssBaseline: {
        '@global': {
          '@font-face': fronts,
        },
      },
      MuiAppBar: {
        root: {
          height: HEADER_HEIGHT,
          boxShadow: 'none',
          zIndex: 1,
        },
        colorPrimary: {
          backgroundColor: 'inherit',
        },
      },
      MuiTypography: {
        h2: {
          fontSize: 24,
          lineHeight: '18px',
          color: colors.backdropOnColor,
        },
        h3: {
          fontSize: 14,
          lineHeight: '22px',
          letterSpacing: '0.5px',
          color: colors.backdropOnColor,
        },
      },
      MuiLink: {
        root: {
          cursor: 'pointer',
        },
      },
      MuiButton: {
        textSecondary: {
          color: colors.backdropOnColor,
          padding: 0,
          minWidth: 22,
          backgroundColor: colors.backdropColor,
        },
        root: {
          textTransform: 'none',
        },
        contained: {
          color: colors.primaryOnColor,
          backgroundColor: colors.primaryColor,
          '@media (hover: hover)': {
            '&:hover': {
              backgroundColor: darken(colors.primaryColor, 0.1),
            },
          },
          '&$disabled': {
            color: colors.primaryOnColor,
            backgroundColor: colors.primaryMutedColor,
          },
        },
      },
      MuiFormLabel: {
        root: {
          fontSize: '14px',
          lineHeight: '22px',
        },
      },
      MuiInputLabel: {
        shrink: {
          transform: 'translate(0, -5px)',
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: colors.surfaceMutedColor,
        },
      },
      MuiSlider: {
        rail: {
          color: colors.surfaceMutedColor,
        },
        valueLabel: {
          zIndex: 20,
          left: 'calc(-50% - -1px)',
        },
      },
      MuiIconButton: {
        root: {
          borderRadius: 'unset',
          '@media (hover: hover)': {
            '&:hover': {
              backgroundColor: 'transparent',
            },
          },
        },
      },
    },
    breakpoints: createBreakpoints({
      values: {
        mobile: 0,
        tablet: 640,
        laptop: 991,
        desktop: 1024,
        mobileFullPlayer: 780,
        trackPageMobile: 700,
      },
    }),
  });

export default createMuiTheme;
