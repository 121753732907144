import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import AUTH_TYPE from 'constants/auth-type';
import ROUTES, { getTabPageRoute } from 'utils/routes';
import useOauthPopup from 'hook/use-oauth-popup';
import useContentPermission from 'hook/use-content-permission';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import NavLink from '../components/nav-link';
import SearchInput from '../components/search-input';
import AccountMenu from './account-menu';
import useStyles from './header.desktop.styles';
import NavItemDropdown from './nav-item-dropdown';

const HeaderDesktop = memo((props) => {
  const { t } = useTranslation();
  const { isAuthenticated, hasContentPermissions } = useContentPermission();
  const { tabs, appName, authType, brandImage, availableSearchWidth } = props;
  const classes = useStyles({ availableSearchWidth });

  const handleWebAuth = useOauthPopup();

  return (
    <AppBar position="absolute" className={classes.header}>
      <Toolbar variant="dense" className={classes.toolBar}>
        <Link to={ROUTES.root}>
          <img src={brandImage} alt={appName} className={classes.logoImage} />
        </Link>
        <nav className={classes.navBar}>
          {isAuthenticated ? (
            <>
              {hasContentPermissions && (
                <>
                  <SearchInput availableSearchWidth={availableSearchWidth} />
                  {tabs.map((tab, index) => {
                    switch (tab.target) {
                      case 'browse_multi':
                        return <NavItemDropdown key={tab.label} label={tab.label} items={tab.children} />;
                      case 'feed':
                      case 'library':
                      default:
                        return (
                          <NavLink
                            key={tab.label}
                            href={getTabPageRoute(tab.target, tab.targetId)}
                            isLast={index === tabs.length - 1}
                          >
                            {t(tab.label)}
                          </NavLink>
                        );
                    }
                  })}
                </>
              )}
              <AccountMenu />
            </>
          ) : (
            <>
              {authType === AUTH_TYPE.firebase && (
                <>
                  <NavLink href={ROUTES.signup}>{t`auth_firebase_button_signup`}</NavLink>
                  <Button
                    component={Link}
                    to={ROUTES.login}
                    className={classes.navButton}
                  >{t`auth_firebase_button_login`}</Button>
                </>
              )}
              {authType === AUTH_TYPE.web && (
                <>
                  <Button onClick={handleWebAuth} className={classes.navButton}>{t`auth_firebase_button_login`}</Button>
                </>
              )}
            </>
          )}
        </nav>
      </Toolbar>
    </AppBar>
  );
});

HeaderDesktop.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      target: PropTypes.string,
      target_id: PropTypes.string,
      features: PropTypes.shape({
        history: PropTypes.bool,
      }),
      children: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          target: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  appName: PropTypes.string.isRequired,
  authType: PropTypes.string.isRequired,
  brandImage: PropTypes.string.isRequired,
  availableSearchWidth: PropTypes.number,
};

export default HeaderDesktop;
