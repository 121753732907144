import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import smoothScroll from 'smoothscroll-polyfill';
import { ConnectedRouter } from 'connected-react-router';
import configureStore, { history } from 'redux-state';
import CssBaseline from '@material-ui/core/CssBaseline';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { getAppConfig } from 'api/config';
import createMuiTheme from 'utils/themes';
import mapGlobalAppConfig from 'utils/config';
import { getPersistentData, PLAYER_PERSIST_KEY } from 'utils/localstorage';
import initI18n from 'utils/i18n';
import initRealm from 'utils/realm';
import { initFirebase, getReactReduxFirebaseProps } from 'utils/auth/firebase';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { initialState as AppReducerInitialState } from 'redux-state/ducks/app';
import { initialState as PlayerReducerInitialState } from 'redux-state/ducks/player';
import { initialState as RealmReducerInitialState } from 'redux-state/ducks/realm';
import App from 'components/app';
import Loader from 'components/loader';
import './index.css';
import reportWebVitals from './reportWebVitals';

// kick off the polyfill!
smoothScroll.polyfill();

// Render Loader while configuration is fetching
ReactDOM.render(<Loader withOverlay />, document.getElementById('root'));

getAppConfig().then((config) => {
  let realmApp = null;
  const mappedConfig = mapGlobalAppConfig(config);
  const { theme, locales, realmConfig, firebaseConfig, overwriteTranslations } = mappedConfig;
  const { realmAppId, databaseName } = realmConfig;
  initFirebase(firebaseConfig);
  initI18n({ locales, overwriteTranslations });

  if (realmAppId) {
    realmApp = initRealm(realmAppId);
  }

  const persistedPlayerData = getPersistentData(PLAYER_PERSIST_KEY);
  const store = configureStore(
    {
      app: { ...AppReducerInitialState, config: mappedConfig },
      player: { ...PlayerReducerInitialState, ...persistedPlayerData },
      realm: { ...RealmReducerInitialState, app: realmApp || null, databaseName },
    },
    { firebaseConfig: config },
  );
  const rrfProps = getReactReduxFirebaseProps(store);
  const muiTheme = createMuiTheme(theme);

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <ThemeProvider theme={muiTheme}>
            <ConnectedRouter history={history}>
              <CssBaseline />
              <App />
            </ConnectedRouter>
          </ThemeProvider>
        </ReactReduxFirebaseProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root'),
  );

  // If you want to start measuring performance in your app, pass a function
  // to log results (for example: reportWebVitals(console.log))
  // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
  reportWebVitals();
});
